<template>
  <div>
    <v-app-bar
      :value="true"
      app
      clipped-left
      :color="this.$vuetify.theme.dark ? 'black' : 'white'"
      elevation="1"
      class="border-bottom-1"
    >
      <div
        class="d-flex align-center mt-5 mb-5"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-img
          lazy-src="img/eBoard-Logo-01.png"
          max-width="150"
          src="img/eBoard-Logo-01.png"
          class="mx-4 my-5"
        ></v-img>

      </div>
      <v-app-bar-nav-icon
        class="ml-auto"
        color="grey"
        @click="toggleDrawer(!mini)"
      >
      </v-app-bar-nav-icon>
      <v-spacer> </v-spacer>
      <span class="ml-200 secondary--text" color="blue-grey">
        <v-img
          lazy-src="img/logo-128.png"
          max-width="84"
          src="img/logo-128.png"
          class="mx-10 my-5"
        ></v-img>
      </span>

      <v-spacer> </v-spacer>
      <v-menu :offset-y="offset" left bottom v-if="$vuetify.breakpoint.mdAndUp">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="grey"
            class="mr-5"
          >
            <v-badge color="red" overlap bordered dot>
              <v-icon color="grey">mdi-bell-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>

        <v-card class="mx-auto" max-width="400" max-height="400" scrollable>
          <div class="py-5 px-5">
            <v-icon>mdi-message-text</v-icon>
            Notifications
            <v-divider class="my-2"> </v-divider>
          </div>
          <v-list class="mt-n7">
            <v-list-item-group
              active-class="error--text text--darken-1"
              multiple
            >
              <template v-for="(item, index) in notifications">
                <v-list-item :key="item.title" @click="changeState(item)">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.title"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="item.headline"
                      ></v-list-item-subtitle>
                      <p class="my-1 grey--text" caption>
                        {{ item.message }}
                      </p>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text
                        v-text="formatDate(item.created_at)"
                      ></v-list-item-action-text>
                      <v-icon v-if="item.read == 0" color="grey lighten-1">
                        mdi-check
                      </v-icon>
                      <v-icon v-else color="error"> mdi-check </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider
                  v-if="index < items.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>

      <span class="mt-5 mr-3 text-right">
        <span class="font-weight-medium"> {{ user.name }}</span>
        <p class="text--grey">{{ user.email }}</p>
      </span>

      <v-menu :offset-y="offset" left bottom class="mr-5">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-avatar v-if="!user.image_url" :color="selectedColor">
              <span class="white--text">{{ getInitials(user.name) }}</span>
            </v-avatar>
            <v-avatar v-else dark v-bind="attrs" v-on="on">
              <img
                :src="user.image_url"
                :lazy-src="user.image_url"
                alt="Profile Image"
                max-width="6"
                max-height="6"
              />
            </v-avatar>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            link
            to="/complete-profile"
            v-if="user.abilities.includes('update-profile')"
          >
            <v-icon class="mr-2">mdi-domain</v-icon>
            <v-list-item-title>Complete Profile</v-list-item-title>
          </v-list-item>
          <v-list-item @click="toggleTheme">
            <v-icon class="mr-2">mdi-video-switch-outline</v-icon>
            <v-list-item-title>{{ themeMode }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="handleLogout">
            <v-icon class="mr-2">mdi-logout-variant</v-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import NotificationService from "../services/notification.service";
import moment from "moment";

export default {
  name: "AppBar",
  props: ["page"],
  data: () => ({
    offset: true,
    selectedColor: "",
    items: [
      {
        title: "My Profile",
        icon: "mdi-account-outline",
        action: "",
      },
      {
        title: "My Company",
        icon: "mdi-briefcase-account-outline",
        action: "",
      },
      {
        title: "My inbox",
        icon: "mdi-email-open-outline",
        action: "",
      },
      {
        title: "Lock Screen",
        icon: "mdi-lock-outline",
        action: "",
      },
    ],
    notifications: [
      {
        action: "15 min",
        headline: "",
        subtitle: `You can now proceed with your application for Tender Number ABC/1215/2020`,
        title: "Tender ABC/1215/2020 ",
      },
    ],
    colors: [
      "pink lighten-5",
      "blue-grey lighten-4",
      "primary lighten-4",
      "cyan lighten-4",
      "red lighten-4",
      "primary",
      "red lighten-3",
      "red accent-1",
      "indigo lighten-3",
      "primary accent-4",
    ],
  }),
  components: {},
  created() {
    const idx = Math.floor(Math.random() * this.colors.length);
    this.selectedColor = this.colors[idx];
    this.getAllUserNotifications();
  },
  computed: {
    mini() {
      return this.$store.state.mini;
    },
    user() {
      return this.$store.state.auth.user;
    },
    companyLogoUrl() {
      return this.$store.state.auth.user.company.company_logo_url;
    },
    themeMode() {
      return !this.$vuetify.theme.dark ? "Dark Mode" : "Light Mode";
    },
  },
  methods: {
    toggleTheme() {
      this.$store.dispatch(
        "auth/setTheme",
        (this.$vuetify.theme.dark = !this.$vuetify.theme.dark)
      );
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    toggleDrawer(value) {
      this.$store.dispatch("minifyDrawer", value);
    },
    getInitials: function (str) {
      return str
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "");
    },
    changeState(item) {
      NotificationService.updateUserNotificationStatus(item).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", response.data.message);
            this.getAllUserNotifications();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    getAllUserNotifications() {
      return NotificationService.getAllUserNotifications(this.user.id).then(
        (response) => {
          if (response.status == 200) {
            this.notifications = response.data.data;
            this.loading = false;
          } else {
            this.notifications = [];
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.notifications = [];
          console.log(error);
        }
      );
    },
  },
};
</script>
