<template>
  <div>
    <!-- large devices  -->
    <v-navigation-drawer
      :mini-variant.sync="mini"
      clipped
      app
      v-model="drawer"
      :permanent="drawer"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <v-list class="mt-1">
        <v-list-item>
          <v-list-item-title class="text-wrap">
            <v-select
              :value="1"
              :items="companyList"
              label="Select company"
              item-text="title"
              item-value="id"
              color="grey"
              prepend-icon="mdi-domain"
              single-line
              hide-details
            >
              <template v-slot:label>
                <span class="input__label"
                  >Tender Category
                  <v-icon small color="error" class="mt-n2"
                    >mdi-star-outline</v-icon
                  ></span
                >
              </template>
            </v-select>
          </v-list-item-title>
          <v-btn icon @click.stop="toggleDrawer(!mini)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense>
        <!-- ADMIN  -->
        <div v-if="user.is_admin == 1">
          <div v-for="item in adminMenu" :key="item.title">
            <!-- START list group  -->
            <v-list-group
              v-if="item.child && user.abilities.includes(item.privilege)"
              :to="item.route"
              color="error"
            >
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-btn
                    :color="item.color"
                    fab
                    dark
                    small
                    class="my-n2 ml-n2"
                    v-if="mini"
                  >
                    <v-icon color="white">{{ item.icon }}</v-icon>
                  </v-btn>
                  <v-btn :color="item.color" fab dark class="my-auto" v-else>
                    <v-icon color="white" x-large>{{ item.icon }}</v-icon>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    :color="item.color"
                    :class="mini ? 'py-2' : 'py-5'"
                  >
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <!-- First child  -->
              <div v-if="item.child && user.abilities.includes(item.privilege)">
                <v-list-item
                  v-for="(subItem, i) in item.child"
                  :key="i"
                  link
                  :to="subItem.route"
                >
                  <v-list-item-title
                    :color="subItem.color"
                    v-text="subItem.title"
                    class="ml-15"
                  >
                  </v-list-item-title>
                  <v-list-item-icon>
                    <v-icon v-text="subItem.icon"></v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </div>
            </v-list-group>
            <!-- END list group  -->
            <!-- START menu list item [has no child]  -->
            <v-list-item
              v-else-if="!item.child && user.abilities.includes(item.privilege)"
              link
              :to="item.route"
              :class="mini ? 'py-0' : 'py-5'"
              color="error"
            >
              <v-list-item-icon>
                <v-btn
                  :color="item.color"
                  fab
                  dark
                  small
                  class="my-n2 ml-n2"
                  v-if="mini"
                >
                  <v-icon color="white">{{ item.icon }}</v-icon>
                </v-btn>
                <v-btn
                  :color="item.color"
                  fab
                  dark
                  class="my-auto mt-n4"
                  v-else
                >
                  <v-icon color="white" x-large>{{ item.icon }}</v-icon>
                </v-btn>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title :color="item.color">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- END menu list item  -->
          </div>
        </div>
        <!-- STANDARD -->
        <div v-else>
          <v-list-item
            v-if="
              user.company.profile_complete == 0 &&
              user.abilities.includes('update-profile')
            "
            link
            to="complete-profile"
            color="error"
          >
            <v-list-item-icon>
              <v-btn
                color="warning"
                fab
                dark
                small
                class="my-n2 ml-n2"
                v-if="mini"
              >
                <v-icon color="white">mdi-account-alert</v-icon>
              </v-btn>
              <v-btn color="grey" fab dark class="my-auto" v-else>
                <v-icon color="white" x-large>mdi-account-alert</v-icon>
              </v-btn>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title color="orange" :class="mini ? 'py-2' : 'py-5'">
                Complete Profile
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="(user.profile_complete = 0)"></v-divider>

          <div v-for="item in publicMenu" :key="item.title">
            <!-- START list group  -->
            <v-list-group v-if="item.child" :to="item.route" color="error">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-btn
                    :color="item.color"
                    fab
                    dark
                    small
                    class="my-n2 ml-n2"
                    v-if="mini"
                  >
                    <v-icon color="white">{{ item.icon }}</v-icon>
                  </v-btn>
                  <v-btn :color="item.color" fab dark class="my-auto" v-else>
                    <v-icon color="white" x-large>{{ item.icon }}</v-icon>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    :color="item.color"
                    :class="mini ? 'py-2' : 'py-5'"
                  >
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <!-- First child  -->
              <div v-if="item.child">
                <v-list-item
                  v-for="(subItem, i) in item.child"
                  :key="i"
                  link
                  :to="subItem.route"
                >
                  <v-list-item-title
                    :color="subItem.color"
                    v-text="subItem.title"
                    class="ml-15"
                  >
                  </v-list-item-title>
                  <v-list-item-icon>
                    <v-icon v-text="subItem.icon"></v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </div>
            </v-list-group>
            <!-- END list group  -->
            <!-- START menu list item [has no child]  -->
            <v-list-item
              v-else-if="!item.child"
              link
              :to="item.route"
              :class="mini ? 'py-0' : 'py-5'"
              color="error"
            >
              <v-list-item-icon>
                <v-btn
                  :color="item.color"
                  fab
                  dark
                  small
                  class="my-n2 ml-n2"
                  v-if="mini"
                >
                  <v-icon color="white">{{ item.icon }}</v-icon>
                </v-btn>
                <v-btn
                  :color="item.color"
                  fab
                  dark
                  class="my-auto mt-n4"
                  v-else
                >
                  <v-icon color="white" x-large>{{ item.icon }}</v-icon>
                </v-btn>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title :color="item.color">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- END menu list item  -->
          </div>
        </div>
        <v-divider></v-divider>
      </v-list>
    </v-navigation-drawer>

    <!-- small devices -->
    <v-navigation-drawer
      :mini-variant.sync="miniMin"
      clipped
      app
      v-model="drawerMin"
      :permanent="drawer"
      v-else
    >
      <v-list class="mt-1">
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-avatar>
          <v-list-item-title class="text-wrap mx-auto">{{
            user.company.company_name
          }}</v-list-item-title>
          <v-btn icon @click.stop="toggleDrawer(!mini)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense>
        <!-- ADMIN  -->
        <div v-if="user.is_admin == 1">
          <div v-for="item in adminMenu" :key="item.title">
            <!-- START list group [has child]  -->
            <v-list-group v-if="item.child" :to="item.route" color="error">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-btn small :color="item.color" fab dark class="mt-n2 ml-n2">
                    <v-icon color="white">{{ item.icon }}</v-icon>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title :color="item.color" class="my-auto">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <!-- First child  -->
              <div v-if="item.child">
                <v-list-item
                  v-for="(subItem, i) in item.child"
                  :key="i"
                  link
                  :to="subItem.route"
                >
                  <v-list-item-title
                    :color="subItem.color"
                    v-text="subItem.title"
                    class="ml-auto"
                  >
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list-group>
            <!-- END list group  -->
            <!-- START menu list item  -->
            <v-list-item
              v-else
              link
              :to="item.route"
              class="py-3"
              color="error"
            >
              <v-list-item-icon>
                <v-btn small :color="item.color" fab dark class="mt-n2 ml-n2">
                  <v-icon color="white">{{ item.icon }}</v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :color="item.color">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- END menu list item  -->
          </div>
        </div>
        <div v-else>
          <v-list-item
            v-if="user.company.profile_complete == 0"
            link
            to="complete-profile"
            class="py-3"
            color="error"
          >
            <v-list-item-icon>
              <v-btn color="warning" fab dark class="mt-n4">
                <v-icon color="white" x-large>mdi-account-alert</v-icon>
              </v-btn>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title color="orange">
                Complete Profile
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="(user.profile_complete = 0)"></v-divider>

          <v-list-item
            v-for="item in publicMenu"
            :key="item.title"
            link
            :to="item.route"
            class="py-3"
            color="error"
          >
            <v-list-item-icon>
              <v-btn :color="item.color" fab dark class="mt-n4">
                <v-icon color="white" x-large>{{ item.icon }}</v-icon>
              </v-btn>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title :color="item.color">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-divider></v-divider>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data: () => ({
    companyList: [
      {
        id: 1,
        title: "EQUITY KENYA",
      },
      {
        id: 2,
        title: "EQUITY TANZANIA",
      },
      {
        id: 3,
        title: "EQUITY SOUTH SUDAN",
      },
      {
        id: 3,
        title: "EQUITY RWANDA",
      },
      {
        id: 3,
        title: "EQUITY UGANDA",
      },
      {
        id: 1,
        title: "EQUITY GROUP FOUNDATION",
      },
    ],
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
    cruds: [
      ["Create", "mdi-plus-outline"],
      ["Read", "mdi-file-outline"],
      ["Update", "mdi-update"],
      ["Delete", "mdi-delete"],
    ],
    offset: true,
    publicMenu: [
      {
        title: "Tenders & Supplies",
        icon: "mdi-shopping",
        route: "/marketplace",
        color: "info",
      },
      {
        title: "My Applications",
        icon: "mdi-file-document",
        route: "/my-applications",
        color: "green",
      },
      {
        title: "Prequalification",
        icon: "mdi-account-tie",
        route: "/register-supplier",
        color: "orange",
      },
      {
        title: "Quotations",
        icon: "mdi-format-quote-close-outline",
        route: "/supplier-quotations",
        color: "primary",
        child: [
          {
            title: "Quotation Requests",
            icon: "mdi-email-send",
            route: "/supply-requests",
            color: "primary",
          },
          {
            title: "Supplier Quotations",
            icon: "mdi-comment-quote-outline",
            route: "/supplier-quotations",
            color: "success",
          },
        ],
      },
      {
        title: "Users",
        icon: "mdi-account-multiple",
        route: "/users",
        color: "info",
      },
      {
        title: "Roles",
        icon: "mdi-account-cog-outline",
        route: "/roles",
        color: "primary",
      },
    ],
    adminMenu: [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        route: "/dashboard",
        color: "blue",
        privilege: "view-dashboard",
      },
      {
        title: "Procurement",
        icon: "mdi-account-tie",
        color: "orange",
        privilege: "view-supplier",
        child: [
          {
            title: "Need Identification",
            icon: "mdi-file-check-outline",
            route: "/need-identification",
            color: "primary",
            privilege: "view-prequalified-supplier",
          },
          {
            title: "Pre-approval",
            icon: "mdi-file-check-outline",
            route: "/requisition",
            color: "primary",
            privilege: "view-prequalified-supplier",
          },
          {
            title: "Supplier Selection",
            icon: "mdi-account-check-outline",
            route: "/supplier-selection",
            color: "primary",
            privilege: "view-prequalified-supplier",
          },
          {
            title: "Authority Approvals",
            icon: "mdi-account-check-outline",
            route: "/authority-approvals",
            color: "primary",
            privilege: "view-prequalified-supplier",
          },
          {
            title: "Contracts",
            icon: "mdi-file-edit-outline",
            route: "/contracts",
            color: "primary",
            privilege: "view-prequalified-supplier",
          },
          {
            title: "Purchase Requisition",
            icon: "mdi-folder-plus-outline",
            route: "/purchase-requisition",
            color: "primary",
            privilege: "view-prequalified-supplier",
          },
          {
            title: "Purchase Order",
            icon: "mdi-folder-plus-outline",
            route: "/generate-lpo",
            color: "primary",
            privilege: "view-prequalified-supplier",
          },
        ],
      },

      {
        title: "Contracts",
        icon: "mdi-file-sign",
        route: "/constract",
        color: "alt",
        privilege: "view-dashboard",
        child: [
          {
            title: "Contracts",
            icon: "mdi-text-box-check-outline",
            route: "/contracts",
            color: "primary",
            privilege: "view-supplier",
          },
          {
            title: "Templates",
            icon: "mdi-clipboard-file-outline",
            route: "/contract-templates",
            color: "primary",
            privilege: "view-prequalified-supplier",
          },
        ],
      },

      {
        title: "Tenders",
        icon: "mdi-file-document-edit",
        route: "/tenders",
        color: "error",
        privilege: "view-tenders",
        child: [
          {
            title: "All Tenders",
            icon: "mdi-file-document-edit-outline",
            route: "/tenders",
            color: "primary",
            privilege: "view-all-tenders",
          },
          {
            title: "Received Bids",
            icon: "mdi-account-arrow-left-outline",
            route: "/bids",
            color: "primary",
            privilege: "view-all-tenders",
          },
          {
            title: "Tender Category",
            icon: "mdi-file-key-outline",
            route: "/tender-category",
            color: "error",
            privilege: "view-tender-category",
          },
          {
            title: "Mandatory",
            icon: "mdi-file-key-outline",
            route: "/mandatory-tender-documents",
            color: "error",
            privilege: "view-mandatory-tender-documents",
          },
          {
            title: "Technical",
            icon: "mdi-file-star-outline",
            route: "/technical-tender-documents",
            color: "error",
            privilege: "view-technical-tender-documents",
          },
          {
            title: "Financial",
            icon: "mdi-file-table-outline",
            route: "/financial-tender-documents",
            color: "error",
            privilege: "view-financial-tender-documents",
          },
          {
            title: "Status",
            icon: "mdi-tag-outline",
            route: "/tenders-status",
            color: "error",
            privilege: "view-tenders-status",
          },
          {
            title: "Quotations",
            icon: "mdi-format-quote-close-outline",
            route: "/quotations",
            color: "primary",
            privilege: "view-prequalified-supplier",
          },
          {
            title: "Generate LPO",
            icon: "mdi-folder-plus-outline",
            route: "/generate-lpo",
            color: "primary",
            privilege: "view-prequalified-supplier",
          },
          {
            title: "Suppliers",
            icon: "mdi-account-tie-outline",
            route: "/suppliers",
            color: "success ligten-1",
            privilege: "view-prequalified-supplier",
          },
          {
            title: "Supplier Categories",
            icon: "mdi-file-cog-outline",
            route: "/supplier-category",
            color: "success ligten-1",
            privilege: "view-supplier-category",
          },
          {
            title: "Registration Preview",
            icon: "mdi-eye-outline",
            route: "/register-supplier-preview",
            color: "orange",
            privilege: "view-supplier",
          },
        ],
      },
      {
        title: "Prequalifications",
        icon: "mdi-account-tie",
        route: "/supplier-prequalifications",
        color: "info",
        privilege: "view-dashboard",
        child: [
          {
            title: "Applications",
            icon: "mdi-file-edit-outline",
            route: "/supplier-prequalifications",
            color: "primary",
            privilege: "view-supplier",
          },
          {
            title: "Suppliers",
            icon: "mdi-account-tie-outline",
            route: "/suppliers",
            color: "primary",
            privilege: "view-prequalified-supplier",
          },
        ],
      },

      {
        title: "Suppliers",
        icon: "mdi-account-tie",
        color: "orange",
        privilege: "view-supplier",
        child: [
          {
            title: "Requisitions",
            icon: "mdi-file-check-outline",
            route: "/requisition",
            color: "primary",
            privilege: "view-prequalified-supplier",
          },
          {
            title: "Quotations",
            icon: "mdi-format-quote-close-outline",
            route: "/quotations",
            color: "primary",
            privilege: "view-prequalified-supplier",
          },
          {
            title: "Generate LPO",
            icon: "mdi-folder-plus-outline",
            route: "/generate-lpo",
            color: "primary",
            privilege: "view-prequalified-supplier",
          },
          {
            title: "Suppliers",
            icon: "mdi-account-tie-outline",
            route: "/suppliers",
            color: "success ligten-1",
            privilege: "view-prequalified-supplier",
          },
          {
            title: "Supplier Categories",
            icon: "mdi-file-cog-outline",
            route: "/supplier-category",
            color: "success ligten-1",
            privilege: "view-supplier-category",
          },
          {
            title: "Registration Preview",
            icon: "mdi-eye-outline",
            route: "/register-supplier-preview",
            color: "orange",
            privilege: "view-supplier",
          },
        ],
      },
      {
        title: "Approvals",
        icon: "mdi-clipboard-check-multiple-outline",
        color: "primary",
        privilege: "view-reports",
        child: [
          {
            title: "Requisition Approvals",
            icon: "mdi-check-outline",
            route: "/requisition-approval",
            color: "primary",
            privilege: "view-prequalified-supplier",
          },
          {
            title: "Groups",
            icon: "mdi-account-multiple-check-outline",
            route: "/approval-groups",
            color: "primary",
            privilege: "view-prequalified-supplier",
          },
          {
            title: "Workflow",
            icon: "mdi-clipboard-flow-outline",
            route: "/workflows",
            color: "primary",
            privilege: "view-prequalified-supplier",
          },
        ],
      },
      {
        title: "Evaluation",
        icon: "mdi-book-edit",
        route: "/evaluation",
        color: "indigo",
        privilege: "view-evaluation",
      },
      {
        title: "Reports",
        icon: "mdi-file-chart",
        route: "/report",
        color: "success",
        privilege: "view-reports",
        child: [
          {
            title: "Evaluation Reports",
            icon: "mdi-file-chart",
            route: "/report",
            color: "success ligten-1",
            privilege: "view-reports",
          },
        ],
      },
      {
        title: "Notifications",
        icon: "mdi-forum",
        route: "/notifications",
        color: "grey",
        privilege: "view-notifications",
        child: [
          {
            title: "Notifications",
            icon: "mdi-bell",
            route: "/user-notifications",
            color: "primary",
          },
          {
            title: "SMS",
            icon: "mdi-message-processing",
            route: "/user-sms",
            color: "primary",
          },
          {
            title: "Email",
            icon: "mdi-email-box",
            route: "/user-emails",
            color: "primary",
          },
          {
            title: "Contact List",
            icon: "mdi-contacts",
            route: "/contacts",
            color: "primary",
          },
          {
            title: "Groups",
            icon: "mdi-account-multiple-plus",
            route: "/notification-groups",
            color: "primary",
          },
          {
            title: "Templates",
            icon: "mdi-chat",
            route: "/notification-templates",
            color: "primary",
          },
          {
            title: "Contact Groups",
            icon: "mdi-account-group",
            route: "/contact-groups",
            color: "primary",
          },
        ],
      },
      // {
      //   title: "Notifications",
      //   icon: "mdi-forum",
      //   route: "/notifications",
      //   color: "grey",
      //   privilege: "view-notifications",
      //   child: [
      //     {
      //       title: "Notifications",
      //       icon: "mdi-envelop",
      //       route: "/business-types",
      //       color: "primary",
      //     },
      //     {
      //       title: "Groups",
      //       icon: "mdi-envelop",
      //       route: "/business-types",
      //       color: "primary",
      //     },
      //     {
      //       title: "Templates",
      //       icon: "mdi-envelop",
      //       route: "/business-types",
      //       color: "primary",
      //     },
      //   ],
      // },
      {
        title: "Settings",
        icon: "mdi-cog-outline",
        route: "/business-types",
        color: "primary",
        privilege: "view-settings",
        child: [
          {
            title: "Settings",
            icon: "mdi-cog-outline",
            route: "/settings",
            color: "primary",
            privilege: "view-settings",
          },
          {
            title: "Business Categories",
            icon: "mdi-domain",
            route: "/business-types",
            color: "primary",
            privilege: "view-business-categories",
          },
          {
            title: "Users",
            icon: "mdi-account-settings",
            route: "/users",
            color: "primary",
            privilege: "view-users",
          },
          {
            title: "Roles",
            icon: "mdi-account-cog-outline",
            route: "/roles",
            color: "primary",
            privilege: "view-roles",
          },
          {
            title: "Privileges",
            icon: "mdi-account-check-outline",
            route: "/privileges",
            color: "primary",
            privilege: "view-privileges",
          },
          {
            title: "Privilege Groups",
            icon: "mdi-account-check-outline",
            route: "/privileges-group",
            color: "primary",
            privilege: "view-privileges-group",
          },
          {
            title: "Countries",
            icon: "mdi-earth-plus",
            route: "/countries",
            color: "primary",
            privilege: "view-privileges-group",
          },
          {
            title: "Currency",
            icon: "mdi-cash",
            route: "/currency",
            color: "primary",
            privilege: "view-privileges-group",
          },
          
          {
            title: "Update profile",
            icon: "mdi-account-alert",
            route: "/complete-profile",
            color: "primary",
            privilege: "update-profile",
          },
        ],
      },
    ],
  }),
  methods: {
    onChartReady() {},
    toggleDrawer(value) {
      this.$store.dispatch("minifyDrawer", value);
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  created() {
    if (!this.user) {
      this.handleLogout();
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.user.is_admin;
    },
    user() {
      return this.$store.state.auth.user;
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set() {
        this.$store.dispatch("minifyDrawer", !this.drawer);
      },
    },
    drawerMin: {
      get() {
        return this.$store.state.drawer;
      },
      set() {
        this.$store.dispatch("minifyDrawer", !this.drawer);
      },
    },
    mini: {
      get() {
        return this.$store.state.mini;
      },
      set() {
        this.$store.dispatch("minifyDrawer", !this.mini);
      },
    },
    miniMin: {
      get() {
        return !this.$store.state.mini;
      },
      set() {
        !this.$store.dispatch("minifyDrawer", !this.mini);
      },
    },
  },
};
</script>
